<!-- 
	author:张洪志
	description:新发基金 
-->
<template>
	<section class="new-fund">
		<fund-nav />
		<div :class="['banner',{'banner-bg':showBannerBg}]">
      <el-carousel height="320px" v-if="banners.length > 0">
        <el-carousel-item v-for="item in banners" :key="item.id">
          <a :href="item.href" :title="item.title">
            <img :src="item.src" :alt="item.title">
          </a>
        </el-carousel-item>
      </el-carousel>
			<!-- <img src="@/static/img/fund-market/new-fund/banner.jpg" alt="新发基金" class="btn" @click="goInvestGuide"> -->
		</div>
		<div class="recommend vw" v-if="recommendFund && recommendFund.length > 0">
			<div class="title">新发推荐</div>
			<div class="sub-title">
				<span>萌芽力量</span>
				<span>-</span>
				<span>潜力无限</span>
				<span>-</span>
				<span>财富新路</span>
			</div>
			<div class="fund-sec flex-between">
				<div class="fund-item" v-for="item in recommendFund" :key="item.id">
					<router-link class="fund-box" :to="{name:'NewFundDetail',params:{fundCode:item.fundCode}}">
						<div class="title"><a>{{item.fundName}}</a></div>
						<div class="sub-title">{{ item.surplusTime > 48 * 60 * 60 ? '募集结束日' : '募集倒计时'}}</div>
						<div class="time" v-html="item.surplusTimes"></div>
						<span class="ico ico-sort-down"></span>
					</router-link>
					<div class="desc">
						{{item.briefintro}}
					</div>
					<a :class="['btn btn-orange-plain', {'disable stop-buy':item.buyStatus === '0'}]" @click="buyFund(item)">立即认购</a>
				</div>
			</div>
		</div>
		<div class="vw">
			<img src="@/static/img/fund-market/new-fund/guide.png" alt="认购流程及要点">
		</div>
    <div class="no-fund" v-if="newFund.length===0 && !moreLoading"></div>
    <div class="fund-list vw">
			<div class="fund-item flex-between" v-for="item in newFund" :key="item.fundCode">
				<div class="left">
					<div class="title">
            <router-link :to="{name:'NewFundDetail',params:{fundCode:item.fundCode}}" class="code orange">{{item.fundCode}}</router-link>
						<router-link :to="{name:'NewFundDetail',params:{fundCode:item.fundCode}}" class="txt">{{item.fundName}}</router-link>
					</div>
					<div class="summary">
						<div class="item">
							<big class="orange">{{ item.risklevel }}</big>
							<small>风险</small>
						</div>
						<div class="item">
							<big>{{ item.fundSetupDate }}</big>
							<small>认购开始</small>
						</div>
						<div class="item">
							<big>{{ item.setupDates }}</big>
							<small>认购结束</small>
						</div>
						<div class="item" v-if="item.saleFeeRate">
							<big class="red">{{item.saleFeeRate}}</big>
							<small>认购费率</small>
						</div>
            <div class="item gray" v-if="false">
              <big>{{item.noFeeRate}}</big>
            </div>
					</div>
					<div class="desc">
						{{item.briefintro}}
					</div>
				</div>
				<div class="right">
					<div class="time" v-html="item.surplusTimes">
					</div>
					<a :class="['buy btn btn-orange', {'disable stop-buy':item.buyStatus === '0'}]"  @click="buyFund(item)">立即认购</a>
				</div>
			</div>
		</div>
		<div class="loading-more vw" v-show="moreLoading">
			<span class="ico ico-rocket"></span> 正在加载…
		</div>
    <buy-guide ref="buyGuide"></buy-guide>
    <DinDetails :showmodel="showBuyConfirm" @cancels="showBuyConfirm=false" @showdelte="showBuyConfirm=false" @confirms="buyConfirm">
      {{buyConfirmTxt}}
    </DinDetails>
  </section>
</template>

<script>
import {getDicts} from '@/api/dict.js'
import {getImgs} from '@/api/other/index.js'
import {fundList} from '@/api/fund.js'
import FundNav from '@/views/fund-market/components/FundNav.vue'
import BuyGuide from '@/views/fund-market/new-fund/components/BuyGuide.vue'
import DinDetails from '@/components/Models.vue'
export default {
	name: 'NewFund',
	components:{
		FundNav,BuyGuide,DinDetails
	},
	data() {
		return {
      banners:[],
      showBannerBg: false,
      
			recommendFund: [],
			newFund: [],
			moreLoading: false,
      pageNum: 0,
      pageTotal: 0,
      
      riskLevels: [],
      
      showBuyConfirm: false,
      buyConfirmTxt: '',
      buyConfirmStep: 0,
      preBuyFund:null
		}
	},
	created() {
    this.getBanner()
		this.getRiskLevel().then(() => {
      this.getRecommendFund()
      this.getNewFund()
    })
	},
	mounted() {
		this.$nextTick(() => {
      let preTop = document.documentElement.scrollTop
			window.addEventListener('scroll',() => {
        if(this.pageNum >= this.pageTotal) {
          return
        }
				let top = document.documentElement.scrollTop
        if(preTop > top) {
          preTop = top
          return
        }
        preTop = top
				let height = document.documentElement.scrollHeight
				let vh = document.documentElement.clientHeight
				let to = height - 650
				let fire = vh + top > to
				if(fire) {
					this.getNewFund()
				}
			},false)
		})
	},
	methods: {
    /** 获取banner */
    getBanner() {
      getDicts('sys_img_user_site').then(result => {
        let imgUseSite = (result.data.find(m => m.dictLabel === 'web端新发基金轮播')||{}).dictValue
        if(imgUseSite) {
          getImgs('1',imgUseSite).then(imgResult => {
            this.banners = Array.from(imgResult.data,m => {
              return {
                title:m.imgName,
                href:m.imgLinkedUrl,
                src:m.imgUrl,
                id:m.imgId
              }
            })
            this.showBannerBg = this.banners.length === 0
          }).catch(() => {
            this.showBannerBg = true
          })
        } else {
          this.showBannerBg = true
        }
      })
    },
		/** 显示投资指南 */
		goInvestGuide() {
			this.$refs.buyGuide.toggleShow(true)
		},
		/** 获取基金风险级别 */
		getRiskLevel() {
			return getDicts('sys_fund_risklevel').then(result => {
				if(!result.data) {
					return
				}
				this.riskLevels = Array.from(result.data, m => ({name:m.dictLabel,code:m.dictValue}))
			})
		},
		/** 新基推荐 */
		getRecommendFund() {
      fundList({
        pageSize: 3,
        pageNum: 1,
        fundStatus: '1',
        setupDate: '1'
        //privateFlag: '0'
      }).then(result => {
        let data = result.data.data
        data.forEach(m => {
          m.surplusTime = parseInt(parseFloat(m.surplusTime) / 1000)
          m.surplusTimes = this.formatSurplusTime(m)
          let interval = setInterval(() => {
            m.surplusTime--
            m.surplusTimes = this.formatSurplusTime(m)
            if(m.surplusTime <= 0) {
              clearInterval(interval)
            }
          },1e3)
        })
        this.recommendFund = data
      })
		},
		/** 新基列表 */
		getNewFund() {
      if(this.moreLoading) {
        return
      }
			this.moreLoading = true
      fundList({
        pageSize: 5,
        pageNum: this.pageNum+1,
        setupDate: '1',
        //privateFlag: '0',
        fundStatus: '1'
      }).then(result => {
        let data = result.data
        data.data.forEach(m => {
          m.risklevel = ((this.riskLevels.find(n => m.ofundRisklevel === n.code)||{}).name||'').replace('风险','')
          m.fundSetupDate = (m.fundSetupDate||'').$formatDate('yyyy.MM.dd')
          m.setupDates = (m.setupDate||'').$formatDate('yyyy.MM.dd')
          if(m.saleFeeRate) {
            m.saleFeeRate = m.saleFeeRate + '%'
          }else {
            m.noFeeRate = '免服务费'
          }
          m.surplusTime = parseInt(parseFloat(m.surplusTime) / 1000)
          m.surplusTimes = this.formatSurplusTime(m)
          let interval = setInterval(() => {
            m.surplusTime--
            m.surplusTimes = this.formatSurplusTime(m)
            if(m.surplusTime <= 0) {
              clearInterval(interval)
            }
          },1e3)
          if(!this.newFund.some(n => n.fundCode === m.fundCode)) {
            this.newFund.push(m)
          }
        })
        this.pageNum++
        this.pageTotal = data.pages
      }).finally(() => {
        this.moreLoading = false
      })
		},
    /** 格式化倒计时 */
    formatSurplusTime(data) {
      let surplusTime = data.surplusTime
      let surplusTimes = ''
      if (surplusTime > 48 * 60 * 60) {
        surplusTimes = (data.setupDate||'').$formatDate('yyyy.MM.dd')
      } else if(surplusTime <= 0) {
        surplusTimes = '申购结束'
      } else {
        let hours = parseInt(surplusTime / 60 / 60)
        hours = hours < 10 ? '0'+hours : hours.toString()
        let minute = parseInt((surplusTime - hours * 60 * 60) / 60)
        minute = minute < 10 ? '0' + minute : minute.toString()
        let second = parseInt(surplusTime % 60)
        second = second < 10 ? '0' + second : second.toString()
        surplusTimes = `<span>${hours[0]}</span><span>${hours[1]}</span><i>:</i><span>${minute[0]}</span><span>${minute[1]}</span><i>:</i><span>${second[0]}</span><span>${second[1]}</span>`
      }
      return surplusTimes
    },
    /** 购买基金 */
    buyFund(fund) {
      if(fund.buyStatus === '0') {
        return;
      }
      this.buyConfirmStep = 0
      let token = this.$store.getters.token;
      let _userInfo = localStorage.getItem("customerInfo");
      if (!token || !_userInfo) {
        this.buyConfirmTxt = '请登录!'
        this.buyConfirmStep = 1
      }else {
        let userInfo = JSON.parse(_userInfo);
        if (!userInfo.acctFlag || userInfo.acctFlag == "0") {
          this.buyConfirmTxt = '请先开户!'
          this.buyConfirmStep = 2
        }else if (!userInfo.addDataFlag || userInfo.addDataFlag == "0") {
          this.buyConfirmTxt = '请补充资料!'
          this.buyConfirmStep = 3
        }else if (!userInfo.tradePwdFlag || userInfo.tradePwdFlag == "0") {
          this.buyConfirmTxt = '请设置交易密码!'
          this.buyConfirmStep = 4
        }else if (!userInfo.riskFlag || userInfo.riskFlag == "0") {
          this.buyConfirmTxt = '请先完成风险测评!'
          this.buyConfirmStep = 5
        }else if(userInfo.riskLevel < fund.ofundRisklevel){
          this.buyConfirmTxt = '当前产品风险等级高于您的风险测评等级，确认购买？'
          this.buyConfirmStep = 6
        }
      }
      this.preBuyFund = fund
      if(!this.buyConfirmStep) {
        this.buyConfirm()
        return
      }      
      this.showBuyConfirm = true
    },
    /** 购买基金确认 */
    buyConfirm() {
      this.showBuyConfirm = false
      if(this.buyConfirmStep === 1) { 
        this.$router.push({ name: "Login" })
        return
      }
      if(this.buyConfirmStep === 2) { 
        this.$router.push({ name: "OpenAccount" });
        return
      }
      if(this.buyConfirmStep === 3) { 
        this.$router.push({ name: "PersonalDataSupply" });
        return
      }
      if(this.buyConfirmStep === 4) { 
        this.$router.push({ name: "OpenAccount" });
        return
      }
      if(this.buyConfirmStep === 5) { 
        this.$router.push({ name: "RiskAssessment" });
        return
      }
      this.$router.push({name:'ProcessPurchase',params:{fundCode:this.preBuyFund.fundCode,newfund: 1}})
    }
  }
}
</script>

<style lang="less" scoped>
	@bgGray:#F3F4F6;
	@red:#D43F3F;
	
	.red{ color:@red;}
	.orange{ color:#CE9B63;}
	
	.btn-orange-plain{ color:#CE9B63; border:1px solid #CE9B63; padding:11px;}	
	.btn-orange-plain:hover {color:#B88141; background: #F3D1AD; padding:12px; border:none; box-shadow: 0 6px 6px #B88141;}
	
	.btn-orange{ color:#CE9B63; background: #FFF5EA; padding:12px;}	
	.btn-orange:hover {color:#fff; background: #F3D2AE; box-shadow: 0 6px 6px #B88141;}
	
	.new-fund{background: @bgGray; padding-bottom: 30px;}
  .banner{ height:320px;}
  .banner-bg{ background: #2A3649 url(../../../static/img/banner/newfund-banner.jpg) no-repeat center; }
  .banner img{ display:block; margin:auto;}
	.banner .btn{ width:100%; height: 320px; background: none;}
	.recommend{ text-align: center; margin-top: 5px; padding-bottom: 40px;
		&>.title{ font-size: 36px; padding: 30px 0 20px 0;}
		.sub-title{ font-size: 1.2em;
			&>span{ margin: 0 0.5em;}
		}
		.fund-sec{ margin-top:30px; justify-content: flex-start;
			.fund-item{ width:31%;  margin:0 1.2%;}
			.fund-box{box-shadow:0 10px 20px #eee; padding:30px; position: relative; display: block;}
			.title{ font:bold 25px/1.8em "microsoft yahei"}
			.time{ color:@red; font:bold 42px/1.5em microsoft yahei; letter-spacing: 2px;
        /deep/i{font-style: normal;}
      }
			.ico{ position: absolute; bottom:-16px; left:46%; font-size: 3em; color:#fff;}
			.desc{ line-height: 2em; margin:1.3em 0; height:4em; overflow: hidden;}
			.btn{ border-radius: 2em; width:150px; display: block; margin:auto; font:bold 16px "microsoft yahei"}
		}
	}
	.fund-list{ background: @bgGray;
		.fund-item{margin-top: 10px; padding: 24px; background: #fff;
			.left{ width:800px;
				.title{ font:bold 20px "microsoft yahei"; padding:6px 0 16px; border-bottom: 1px solid #ddd;
					.txt{ margin-left:1em;}
				}
				.summary{ display: flex; padding: 25px 0; text-align: center; align-items: center;
					.item{ line-height: 1.6em; font-size: 15px; display: flex;flex-flow: column; margin-right: 100px;}
				}
				.desc{ font-size: 12px; color:#999; line-height: 1.5em; max-height: 3em; overflow: hidden;}
			}
			.right{ width:260px; display: flex;flex-flow: column; align-items: flex-end;
				.time{ color:@red; font:36px/60px "microsoft yahei"; margin-top:20px;
					/deep/span{background: #F9DEB5;background-image: linear-gradient(110deg,#F5CF93 20%,#FFF8EC,#FCECD3 80%); padding:0px 5px; margin-right: 2px;}
					/deep/i{ font-style:normal; padding:0px 8px;}
				}
				.buy{ margin-top: 50px; display: block; width:160px; text-align: center; font:bold 16px "microsoft yahei"; letter-spacing: 1px;}
			}
		}
	}
  .no-fund{ background:url(../../../static/img/fund-market/new-fund/no-fund.png) no-repeat center center; height:555px;}
	.loading-more{ text-align: center; background-color: @bgGray; color:#868686; line-height: 8em; font-size: 1.1em;}
</style>
